import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Col, Empty, Row, Select, Space, Spin, Tabs, Typography } from 'antd';
import { CloseOutlined, ReloadOutlined, UploadOutlined } from '@ant-design/icons';
import SimpleBar from 'simplebar-react';
import AuthUserAvatar from 'components/AuthUserAvatar';
import FileUploadModal from 'components/FileUpload';
import RichTextEditor from 'components/RichTextEditor';
import { UploadFileApi, UploadResponse, UploadS3Response } from 'types/upload-type';
import { checkIsAdmin } from 'utils/auth';
import { mentionCommentParser, getMentionUuidFromNode } from 'utils/string.utils';
import { UserResponse } from 'types';
import CommentCard from './CommentCard';
import useComments from './useComments';
import styles from './styles.module.less';
import TemplateCommentSearch from './TemplateCommentSearch';

const { Text } = Typography;

interface CommentsProps {
  highlightUuid?: string;
  typeUuid: string;
  type: 'tasks' | 'projects' | 'requests';
  maxHeight?: string;
  projectUuid: string;
  scrollingContainer?: string | HTMLElement | undefined;
  hideCommentInput?: boolean;
  hideComments?: boolean;
  hideTemplates?: boolean;
  isProjectPersonal?: boolean;
  hideTabs?: any;
  onAddComment?: () => void | undefined;
  onFinishSubmit?: (values: any, images: UploadFileApi[]) => void;
  onFilesDelete?: (uuid: string[]) => void;
  onCommentsLoaded?: any;
  onImageModalChanged?: (open: boolean) => void;
}

const Comments: FC<CommentsProps> = ({
  highlightUuid,
  typeUuid,
  type,
  maxHeight,
  projectUuid,
  scrollingContainer,
  hideCommentInput,
  hideComments,
  hideTemplates,
  isProjectPersonal,
  hideTabs,
  onAddComment,
  onFinishSubmit,
  onFilesDelete,
  onCommentsLoaded,
  onImageModalChanged,
}) => {
  const user: UserResponse = useSelector((store: any) => store.auth.user);
  const isAdmin = checkIsAdmin(user);

  const {
    inputRef,
    state,
    commentType,
    scrollRef,
    tabView,
    setCommentType,
    setTabView,
    setComment,
    reloadComment,
    handleUpdateComment,
    handleDeleteComment,
    handleAddComments,
    loadMore,
    handleDeleteUpload,
    handleUpdateReply,
    handleDeleteReply,
    handleAddReply,
    setReplyTo,
    handleAddReaction,
    handleRemoveReaction,
    handleRemoveReplyReaction,
    handleAddReplyReaction,
  } = useComments({ highlightUuid, typeUuid, type, onAddComment, onCommentsLoaded, onFilesDelete, onFinishSubmit });
  const [visible, setVisible] = useState(false);
  const [images, setImages] = useState<UploadFileApi[]>([]);
  const [loadingImages, setLoadingImages] = useState(0);

  const { data, loading, hasMore, adding, replyTo, replyToUuid } = state;

  const handleAddImages = (uploads: UploadFileApi[]) => {
    setImages([...images, ...uploads]);
    setVisible(false);
  };

  const handleDeleteImage = (imageUuid: string) => {
    setImages([...images.filter((el) => el.response?.uuid !== imageUuid)]);
  };

  const handleSubmit = () => {
    const comment = inputRef.current?.getValue();

    if (!comment || inputRef.current?.isEmpty()) return;

    const values = {
      text: comment,
      related_users: getMentionUuidFromNode(comment),
      type: commentType,
      uploads: images.map((el) => el.response?.uuid),
    };

    const statedImages: UploadFileApi[] = images.map((file) => {
      return { ...file, response: { ...file.response, stated: true } as UploadS3Response };
    });

    if (replyTo && replyToUuid) {
      handleAddReply(replyToUuid, values, () => {
        setComment('');
        setImages([]);
        setReplyTo(null, null);
        onFinishSubmit?.(values, statedImages);
      });
    } else {
      handleAddComments(values, () => {
        setComment('');
        setImages([]);
        onFinishSubmit?.(values, statedImages);
      });
    }
  };

  const showTabs = type === 'projects' && isAdmin && !hideTabs;

  const getCommentBody = (tabType?: string) => (
    <>
      <FileUploadModal onOk={handleAddImages} visible={visible} closeUploadModal={() => setVisible(false)} />
      {!hideComments && (
        <Spin spinning={loading}>
          <div>
            <SimpleBar className="bar-default" ref={scrollRef} style={{ maxHeight: maxHeight ?? '80vh', width: '100%' }}>
              <div style={{ width: '100%', overflowX: 'hidden', padding: 0 }}>
                {hasMore && (
                  <Row justify="center">
                    {loading ? (
                      <Text className="primary">Loading...</Text>
                    ) : (
                      <Button className="primary" onClick={loadMore} type="text">
                        Load more
                      </Button>
                    )}
                  </Row>
                )}

                {data.map((comment) => (
                  <CommentCard
                    key={comment.uuid}
                    comment={comment}
                    targetCommentId={highlightUuid}
                    projectUuid={projectUuid}
                    user={user}
                    type={type}
                    onReplyTo={setReplyTo}
                    onImgDelete={handleDeleteUpload}
                    onAddReaction={handleAddReaction}
                    onRemoveReaction={handleRemoveReaction}
                    onDelete={async (uuid: string) => {
                      await handleDeleteComment(uuid);
                      onFilesDelete?.(comment.uploads.map((item: UploadResponse) => item.uuid));
                    }}
                    onUpdate={handleUpdateComment}
                    onImageModalChanged={onImageModalChanged}
                  >
                    {comment.replies.map((reply: any) => {
                      return (
                        <CommentCard
                          key={reply.uuid}
                          parentEl={comment}
                          comment={reply}
                          targetCommentId={highlightUuid}
                          projectUuid={projectUuid}
                          user={user}
                          type={type}
                          onReplyTo={setReplyTo}
                          onImgDelete={handleDeleteUpload}
                          onAddReaction={handleAddReplyReaction}
                          onRemoveReaction={handleRemoveReplyReaction}
                          onDelete={async (uuid, parentUuid) => {
                            await handleDeleteReply(uuid, parentUuid);
                            onFilesDelete?.(comment.uploads.map((item: UploadResponse) => item.uuid));
                          }}
                          onUpdate={handleUpdateReply}
                          onImageModalChanged={onImageModalChanged}
                        />
                      );
                    })}
                  </CommentCard>
                ))}

                {data.length === 0 && !loading && <Empty style={{ padding: '12px 10px 24px' }} />}
              </div>
            </SimpleBar>
          </div>
        </Spin>
      )}

      {!hideCommentInput && (
        <Space size={4} style={{ width: '100%', padding: '10px 0' }} direction="vertical">
          <Space wrap>
            {images.map((el) => {
              return (
                <div
                  style={{
                    padding: 5,
                    background: '#F0F0F0',
                  }}
                  key={el.response?.uuid}
                >
                  <Space align="center">
                    {el.name}
                    <CloseOutlined onClick={() => handleDeleteImage(el.response?.uuid ?? '')} />
                  </Space>
                </div>
              );
            })}
          </Space>
          {replyTo && (
            <div className={styles.replyTo}>
              <div className={styles.line} />
              <Row justify="space-between" gutter={16} wrap={false} align="middle">
                <Col flex={1}>
                  <h3>{replyTo.creator?.name}</h3>

                  <RichTextEditor
                    key={replyTo.uuid}
                    className="comment-styles resume"
                    initialValue={mentionCommentParser(replyTo.text)}
                    readonly
                    noContainer
                  />
                </Col>
                <Col>
                  <CloseOutlined onClick={() => setReplyTo(null, null)} />
                </Col>
              </Row>
            </div>
          )}

          {!hideTemplates && (
            <TemplateCommentSearch
              onTemplateClick={(template) => {
                setComment(template.content);
              }}
            />
          )}

          <Row gutter={8}>
            <Col>{!hideComments && <AuthUserAvatar />}</Col>

            <Button
              onClick={() => setVisible(true)}
              icon={<UploadOutlined />}
              type="text"
              style={{ fontSize: '28px !important' }}
            />

            {type === 'projects' && isAdmin && (tabType === 'all' || hideTabs) && (
              <Col>
                <Select
                  onChange={(value) => setCommentType(value)}
                  disabled={replyTo?.type === 'private'}
                  options={[
                    {
                      label: 'Private note',
                      value: 'private',
                    },
                    {
                      label: 'Comment',
                      value: 'comment',
                    },
                    {
                      label: 'Call log',
                      value: 'call_log',
                    },
                    {
                      label: 'Email log',
                      value: 'email_log',
                    },
                    {
                      label: 'Summary',
                      value: 'summary',
                    },
                  ]}
                  style={{ width: 150 }}
                  value={commentType}
                />
              </Col>
            )}

            <Col flex="1">
              <RichTextEditor
                ref={inputRef}
                includeToolbar={false}
                includeMention={!isProjectPersonal}
                projectId={projectUuid}
                placeholder="Add a comment"
                scrollingContainer={scrollingContainer}
                onImageUploader={async (upload) => {
                  setImages((prev) => [...prev, upload]);
                }}
                mentionTypes={commentType === 'private' ? ['regular_user', 'group'] : undefined}
                onLoadingImage={setLoadingImages}
                onShortEnterKey={handleSubmit}
                onImageModalChanged={onImageModalChanged}
              />
            </Col>

            <Col>
              <Button loading={adding} disabled={loadingImages > 0} onClick={handleSubmit} type="primary">
                Send
              </Button>
            </Col>
          </Row>
        </Space>
      )}
    </>
  );

  return showTabs ? (
    <Tabs
      tabBarExtraContent={<Button type="text" icon={<ReloadOutlined />} onClick={reloadComment} />}
      activeKey={tabView}
      onChange={(view) => {
        setTabView(view);
        if (view === 'all') {
          setCommentType('comment');
        } else {
          setCommentType(view);
        }
      }}
    >
      {[
        {
          label: 'All',
          value: 'all',
        },
        {
          label: 'Comment',
          value: 'comment',
        },
        {
          label: 'Call log',
          value: 'call_log',
        },
        {
          label: 'Email log',
          value: 'email_log',
        },
        {
          label: 'Private note',
          value: 'private',
        },
        {
          label: 'Summary',
          value: 'summary',
        },
      ].map((el) => {
        return (
          <Tabs.TabPane tab={el.label} key={el.value}>
            {getCommentBody(el.value)}
          </Tabs.TabPane>
        );
      })}
    </Tabs>
  ) : (
    getCommentBody()
  );
};

export default Comments;
