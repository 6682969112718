import { FC, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { IoLogOutOutline } from 'react-icons/io5';
import { PiBugBeetleThin } from 'react-icons/pi';
import { HiOutlineUserCircle } from 'react-icons/hi2';
import { TbClockPause } from 'react-icons/tb';
import { feedbackIntegration } from '@sentry/react';
import { Dropdown, Menu, Button, Drawer } from 'antd';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';
import AuthUserAvatar from 'components/AuthUserAvatar';
import appRoutes from 'config/appRoutes';
import { checkIsSuperUser } from 'config/permissions';
import { logout } from 'store/container/auth/auth-slice';
import { getRouteConfig, MenuItemBaseLinkType } from '../Sidebar/config';
import GlobalSearch from '../GlobalSearch';
import MenuItem from '../Sidebar/MenuItem';

import style from './index.module.less';
import sideBarStyle from '../Sidebar/index.module.less';

const sentryFeedback = feedbackIntegration({
  colorScheme: 'system',
  autoInject: false,
});

const sentryWidget = sentryFeedback.createWidget();

sentryWidget.el.style.display = 'none';

const version = process.env.REACT_APP_GIT_HASH ? process.env.REACT_APP_GIT_HASH.substring(0, 6) : null;

interface UserDropdownProps {
  user: any;
  activeRoute?: MenuItemBaseLinkType;
}

const UserDropdown: FC<UserDropdownProps> = ({ user, activeRoute }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [menuOpen, setMenuOpen] = useState(false);

  const breakpoints = useBreakpoint();

  const fullScreen = !breakpoints.sm;

  const button = (
    <Button type="link" size="large" style={{ padding: 0 }} onClick={fullScreen ? () => setMenuOpen(true) : undefined}>
      <AuthUserAvatar size="large" />
    </Button>
  );

  const profileItem = (
    <Menu.Item key="profile" icon={<HiOutlineUserCircle strokeWidth="1" />} onClick={() => setMenuOpen(false)}>
      <Link to={`${appRoutes.CONTACTS}/${user?.contact?.uuid}/edit`}>Profile</Link>
    </Menu.Item>
  );

  const items = (
    <>
      <Menu.Item
        key="bug"
        icon={<PiBugBeetleThin />}
        onClick={() => {
          sentryWidget.el.click();
          setMenuOpen(false);
        }}
      >
        Report bug
      </Menu.Item>

      <Menu.Item key="time-off" icon={<TbClockPause strokeWidth="1" />} onClick={() => setMenuOpen(false)}>
        <a href="https://savageglobalmarketing.typeform.com/to/yZFxyRL5" target="_blank" rel="noreferrer">
          Request time-off
        </a>
      </Menu.Item>

      <Menu.Item key="divder-2" className="ant-menu-item-divider" />

      <Menu.Item
        key="logout"
        icon={<IoLogOutOutline />}
        onClick={(event) => {
          event.domEvent.stopPropagation();
          event.domEvent.preventDefault();
          setMenuOpen(false);
          dispatch(
            logout({
              onLoaded: () => history.push(appRoutes.LOGIN),
            }),
          );
        }}
      >
        Logout
      </Menu.Item>
    </>
  );

  if (!breakpoints.sm) {
    const isSuperUser = checkIsSuperUser(user?.email);
    const config = getRouteConfig();

    return (
      <>
        {button}
        <Drawer
          visible={menuOpen}
          title={<span className={style.menuTitle}>lionshare</span>}
          placement="right"
          width={600}
          className={style.drawerMenu}
          footer={version ? `Ver: ${version}` : undefined}
          onClose={() => setMenuOpen(false)}
        >
          <div className={sideBarStyle.sidebar} style={{ border: 'none' }}>
            <Menu mode="inline" theme="light" selectable={false}>
              <Menu.Item key="search" className={sideBarStyle.searchItem}>
                <GlobalSearch />
              </Menu.Item>

              {profileItem}

              <Menu.Item key="divder-1" className="ant-menu-item-divider" />

              {config.map((item) => (
                <MenuItem
                  key={`${'path' in item ? item.path : item.key}`}
                  item={item}
                  user={user}
                  classNameLabel={sideBarStyle.itemLabel}
                  isSuperUser={isSuperUser}
                  onClick={() => setMenuOpen(false)}
                  selectedItem={activeRoute && 'path' in activeRoute ? activeRoute : undefined}
                />
              ))}

              {items}
            </Menu>
          </div>
        </Drawer>
      </>
    );
  }

  return (
    <Dropdown
      placement="bottomRight"
      trigger={['click']}
      overlayClassName={style.dropdownOverlay}
      overlay={
        <Menu className={style.dropdownMenu} theme="light">
          {profileItem}
          {items}
        </Menu>
      }
    >
      {button}
    </Dropdown>
  );
};

export default UserDropdown;
